<template>
  <div>
    <a-form layout="vertical">
      <a-row :gutter="16">
        <a-col :span="10">
          <a-form-item
            label="Schedule Setting Name"
          >
            <a-input v-model="data.name" @change="e => handleChange(e.target.value, 'name')" size="large" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item>
            <span slot="label">
              Status&nbsp;
              <a-tooltip title="If it is set to active, last active's schedule setting will be inactivated.">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <a-radio-group
            v-model="data.status"
            size="large"
            class="w-100"
            button-style="solid"
            @change="e => handleChange(e.target.value, 'status')"
            >
              <a-radio-button
                value="active"
                class="w-50 text-center"
                >Active</a-radio-button
              >
              <a-radio-button
                value="inactive"
                class="w-50 text-center"
                >Inactive</a-radio-button
              >
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <p class="mb-3">Add New Lesson Hours</p>
      <a-row :gutter="16" class="border rounded px-2 pt-4 m-0">
        <a-col :span="5">
          <a-form-item
            label="Start At"
          >
            <a-time-picker @change="e => handleChangeNewLessonHours(e, 'startAt')" class="w-100" v-model="newLessonHours.startAt" format="HH:mm" size="large"  />
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item
            label="End At"
          >
            <a-time-picker @change="e => handleChangeNewLessonHours(e, 'endAt')" v-model="newLessonHours.endAt" class="w-100" format="HH:mm" size="large"  />
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item
            label="Duration (minutes)"
          >
            <a-input
              v-model="newLessonHours.duration"
              :min="0"
              :max="120"
              step="1"
              size="large"
              disabled
              class="w-100"
            />
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item
            label="Hours Type"
          >
            <a-select size="large" v-model="newLessonHours.lessonHoursType">
              <a-select-option
                v-for="(item) in listHoursTypes"
                :key="item.value"
                :value="item.value"
              >{{ item.title }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label=" ">
            <a-button
                :disabled="validateAdd"
                @click.prevent="addNewLessonHours()"
                type="primary"
                icon="plus"
                size="large"
                class="w-100 mt-1"
                >Add</a-button
              >
          </a-form-item>
        </a-col>
      </a-row>
      <p class="mb-3 mt-4">Lesson Hours List</p>
      <a-row :gutter="16" class="">
        <a-col :span="24">
          <a-table
            :columns="columns"
            :data-source="data.schedules"
            :pagination="false"
            bordered
            size="large"
          >
            <div slot="position" slot-scope="value, record">{{position(record.key)}}</div>
            <div slot='duration' slot-scope="value">{{value}} minutes</div>
            <div slot="action" slot-scope="value, record">
              <a-button
                class="text-danger border border-danger"
                size="large"
                @click.prevent="deleteHours(record)"
              >
                <a-icon type="delete" />Delete
              </a-button>
            </div>
          </a-table>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
const columns = [
  {
    title: 'Position',
    align: 'center',
    scopedSlots: { customRender: 'position' },
    width: 100,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    align: 'center',
  },
  {
    title: 'Time',
    dataIndex: 'timeStr',
    align: 'center',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    align: 'center',
    scopedSlots: { customRender: 'duration' },
    width: 200,
  },
  {
    title: 'Action',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newLessonHours: {
        startAt: null,
        endAt: null,
        lessonHoursType: 'kbm',
        duration: null,
      },
      columns,
      listHoursTypes: [
        {
          value: 'kbm',
          title: 'Lesson',
        },
        {
          value: 'istirahat',
          title: 'Break',
        },
      ],
    }
  },
  methods: {
    position(key) {
      // console.log(this.data.schedules.map(schedule => schedule.key).indexOf(key), key)
      return this.data.schedules.map(schedule => schedule.key).indexOf(key) + 1
    },
    handleChange(value, key) {
      const payload = {
        value,
        key,
      }
      this.$emit('handle-change', payload)
    },
    addNewLessonHours() {
      const { lessonHoursType, startAt, endAt, duration } = this.newLessonHours
      if (duration < 0) {
        return this.$notification.error({
          message: 'Failed',
          description: "Your end time's input is before your start time's input!",
        })
      } else if (duration === 0) {
        return this.$notification.error({
          message: 'Failed',
          description: "Your end time's input is equal with your start time's input!",
        })
      }
      for (let i = 0; i < this.data.schedules.length; i++) {
        const schedule = this.data.schedules[i]
        if (startAt.isBetween(schedule.startAt, schedule.endAt, 'minutes') || endAt.isBetween(schedule.startAt, schedule.endAt, 'minutes')) {
          return this.$notification.error({
            message: 'Failed',
            description: "Your time's input is between any existed lesson hours",
          })
        }
      }
      const type = lessonHoursType === 'istirahat' ? 'BREAK' : 'LESSON'
      const timeStr = `${startAt.format('HH:mm')} - ${endAt.format('HH:mm')}`
      const key = this.data.schedules.length + 1
      // console.log(this.data, 'dsjns')
      const payload = this.data.schedules.concat([{
        ...this.newLessonHours,
        key,
        type,
        timeStr,
      }])
      this.$emit('handle-change', {
        value: payload,
        key: 'schedules',
      })
      this.newLessonHours = {
        startAt: null,
        endAt: null,
        lessonHoursType: 'kbm',
        duration: null,
      }
    },
    handleChangeNewLessonHours(data, key) {
      if (this.newLessonHours.endAt && this.newLessonHours.startAt) {
        if (key === 'startAt') {
          // if (this.newLessonHours.endAt.isBefore(data, 'minutes')) this.newLessonHours.startAt = this.newLessonHours.endAt
          this.newLessonHours.duration = this.newLessonHours.endAt.diff(data, 'minutes')
        } else if (key === 'endAt') {
          // if (this.newLessonHours.startAt.isAfter(data, 'minutes')) this.newLessonHours.endAt = this.newLessonHours.startAt
          this.newLessonHours.duration = data.diff(this.newLessonHours.startAt, 'minutes')
        }
      } else if (this.newLessonHours.endAt || this.newLessonHours.startAt) this.newLessonHours.duration = 0
      else this.newLessonHours.duration = null
    },
    deleteHours(data) {
      const payload = this.data.schedules.filter(lessonHours => lessonHours.key !== data.key || lessonHours.id !== data.id)
      this.$emit('handle-change', {
        value: payload,
        key: 'schedules',
      })
    },
  },
  computed: {
    validateAdd() {
      let isNull = false
      for (const key in this.newLessonHours) {
        if (this.newLessonHours[key] == null) {
          isNull = true
          break
        }
      }

      return isNull
    },
  },
}
</script>

<style>

</style>
